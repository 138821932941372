

export default function FilterTabHomeMini({
     filter
    ,set_filter
    ,tab_value
    ,tab_text
    ,hint
}) {
    return (<>
    <div
                                        
                                        className={`flex-1 text-center cursor-pointer relative text-xs items-center content-center px-2 py-1 
                                                     text-md
                                                     
                                                    ${filter == tab_value
                                                        ? "bg-gray-700 font-bold" 
                                                        : "hover:bg-gray-800"} 
                                                `}
                                        onClick={() => {
                                            set_filter(tab_value)
                                           
                                        }}
                                    >
                                        <div className="flex-0">
                                            {tab_text}
                                        </div>
                                        {hint > 0 &&
                                        <div className=" z-10 absolute top-0 right-0 -mr-2 -mt-2 text-center  bg-red-500 text-white text-3xs w-4 h-4 rounded-full flex justify-center items-center contents-center">
                                            {hint}
                                        </div>
                                        }
                                    </div>
    </>)
}