

import { useSession } from "next-auth/client"
import Link from "next/link"
import * as React from "react"
import ScheduleMenuOptions from "../schedule/schedule-menu-options"
import { slugify } from "@/lib/utils/slugify"
import { Fragment, useCallback, useEffect, useState } from "react"
import { hasData } from "@/lib/utils/hasData"
import Image from 'next/legacy/image'
import { config, user_types } from "@/lib/config"
import ProfileArray from "../item/profile-array"
import _ from 'underscore';
import SvgJsx from "@/components/templateux/svg/svg-jsx"
import ScheduleSingleMini from "../schedule/schedule-single-mini"
import ButtonSecondary from "@/components/templateux/button/button-secondary"
import { openWindow } from "@/lib/utils/openWindow"
import RotaryLogo from "@/components/templateux/rotary-logo"
import ScheduleSingleMiniHome from "./schedule-single-mini-home"

export default function ScheduleSingleHome({ 
               itemdata
              ,placeholderdata
              ,userdata
              ,ownerdata
              ,placeholder
              ,details_collapsed
              ,display_view
              ,cache
              ,set_cache
              ,nodata
              ,navtype
              ,helptype
              ,isAdmin
             }) {
               

    const [display_data,set_display_data] = useState( placeholder 
                                                    ? placeholderdata 
                                                    : itemdata )

 
    
    useEffect(() => {
      if (!placeholder) {
        set_display_data(itemdata);
      }
      
    },[
       itemdata
      ,placeholder
    ])

    const getWeekday = (num,display_view) => {
      if (num?.toString() == "1") return display_view=="mini" ? "Sun" : "Sundays";
      if (num?.toString() == "2") return display_view=="mini" ? "Mon" : "Mondays";
      if (num?.toString() == "3") return display_view=="mini" ? "Tue" : "Tuesdays";
      if (num?.toString() == "4") return display_view=="mini" ? "Wed" : "Wednesdays";
      if (num?.toString() == "5") return display_view=="mini" ? "Thu" : "Thursdays";
      if (num?.toString() == "6") return display_view=="mini" ? "Fri" : "Fridays";
      if (num?.toString() == "7") return display_view=="mini" ? "Sat" : "Saturdays";
      if (num?.toString() == "0") return display_view=="mini" ? "..." : "Loading";
      return num
    }

    const getFrequency = (num) => {
      if (num?.toString() == "adhoc") return "Whenever";
      if (num?.toString() == "daily") return "Daily";
      if (num?.toString() == "weekly") return "Weekly";
      if (num?.toString() == "bi-weekly") return "Bi-Weekly";
      if (num?.toString() == "monthly") return "Monthly";
      if (num?.toString() == "annually") return "Annually";
      return num
    }

    const getNormalTime = (time,include_meridian) => {

      if (!hasData(time)) return '';

      let timearr = time?.split(':')
      var hours = timearr[0];
      hours = Number(hours);
      var minutes = timearr[1] == '00' ? '' : `:${timearr[1]}`;

      var meridian = (hours < 12) ? 'am' : 'pm';
      if (meridian == 'pm') hours = hours-12;
      if (hours == 0) hours = 12;

      if (include_meridian) {
        return `${hours}${minutes}${meridian}`
      } else {
        return `${hours}${minutes}`
      }

      
    }


      return (<>
        <ScheduleSingleMiniHome
          display_data={display_data}
          getWeekday={getWeekday}
          getNormalTime={getNormalTime}
          userdata={userdata}
        />
      </>)

  
}

  