import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { config, homebarlist, homebarlist_sub, meta,  omitArrayItemsWithMatchingKeyValuePair,  tabarray_posts_filter,  tabarray_posts_global, tabarray_posts_global_view} from '@/lib/config'
import { useUserAuth } from '@/lib/utils/hooks/useUserAuth'
import getMessage from '@/lib/utils/getMessage'
import { getCacheDate } from '@/lib/utils/getCacheDate'
import PostArray from '@/components/profile/post/post-array'
import getRecordArraySwr from '@/lib/utils/admin/server/record/getRecordArraySwr'
import LayoutPageBlank from '@/components/templateux/layout/layout-page-blank'
import NavPrimary from '@/components/templateux/nav/nav-primary'
import Footer from '@/components/templateux/footer'
import Tabs from '@/components/templateux/tabs'
import { signIn, useSession } from 'next-auth/client'
import { useRouter } from 'next/router'
import HeroCTA from '@/components/content/hero-cta'
import Link from 'next/link'
import ProfileAvatarLive from '@/components/profile/item/profile-avatar-live'
import useDraggableScroll from 'use-draggable-scroll'
import getRecordArray from '@/lib/utils/admin/server/record/getRecordArray'
import { LayoutPageBlankContentWrapper } from '@/components/templateux/layout/layout-page-blank-content-wrapper'
import FilterHeaderHome from '@/components/profile/filter-header-home'
import { useSWRWrapper } from '@/lib/utils/hooks/swr-hooks'
import FilterTabHomeMini from '@/components/profile/filter-tab-home-mini'
import { openWindow } from '@/lib/utils/openWindow'
import PopupMenuOptionLink from '@/components/templateux/popup-menu-option-link'
import HeaderBarHome from '@/components/templateux/header-bar/header-bar-home'
import ScheduleArrayHome from '@/components/profile/schedule-home/schedule-array-home'
import SvgJsx from '@/components/templateux/svg/svg-jsx'
import { startPlayer } from '@/lib/utils/startPlayer';
import { PushContext } from '@/components/context/push-wrapper'
import { PlayerContext } from '@/components/player/player-wrapper'
import useTimestamp from '@/lib/utils/hooks/useTimestamp'
import getServerSideSecurity from '@/lib/utils/admin/server/getServerSideSecurity'
import { hasData } from '@/lib/utils/hasData'
import { textProcess } from '@/lib/utils/textprocess'

export async function getServerSideProps(context) {

  //log the visit and return other relevant page / user data
  const {forbidden,user, ip, cookie, ssr_data_tribe} = await getServerSideSecurity(context)
  if (forbidden !== '') {context.res.write(forbidden); context.res.end();}
  
  const ssr_data_posts = 
        await getRecordArraySwr(
              "post" //table_name: string
            , "select_by_featured" //path_name: string
            , "0" //user_id: string
            , "0" //my_id:string
            , ssr_data_tribe?.tribe_id //tribe_id: string
            , "1" //page: string
            , "RAND" //sort: string
            , config.itemdir //dir: string
            , '' //filter: string
        )

    const ssr_data_schedule = 
        await getRecordArray(
              "user_schedule" //table_name: string
            , "select_by_all_homepage" //path_name: string
            , "0" //user_id: string
            , ssr_data_tribe?.tribe_id //tribe_id: string
            , "0" //page: string
            , "RAND" //sort: string
            , config.itemdir //dir: string
            , '' //filter: string
          )
            
    const ssr_data_live = 
    await getRecordArray(
          "user" //table_name: string
        , "select_by_live" //path_name: string
        , "0" //user_id: string
        , ssr_data_tribe?.tribe_id //tribe_id: string
        , "0" //page: string
        , "broadcast" //sort: string
        , config.itemdir //dir: string
        , '' //filter: string
      )
          

  const navurl_relative = `/`
  
  return { 
      props: { 
         ssr_data_tribe: ssr_data_tribe
         ,ssr_data_posts: ssr_data_posts
         ,ssr_data_live: ssr_data_live
         ,ssr_data_schedule: ssr_data_schedule
        //  ,ssr_data_hosts: ssr_data_hosts
         ,params: context?.params || null
        ,navtype: 'posts'
        ,navoption: 'featured'
        ,navtitle: 'Home Page'
        ,navurl: `${ssr_data_tribe?.server_website}${navurl_relative}`
        ,navurl_relative: navurl_relative
        ,helptype: 'content'
        ,nodata: getMessage("noresults",ssr_data_tribe)
      } 
      //,revalidate: 300, // In seconds
    }
}

// export async function getStaticPaths() {

//   return {
//     paths: [],
//     fallback: 'blocking',
//   }
// }

function IndexPosts({ 
                            ssr_data_tribe
                          , ssr_data_posts
                          , ssr_data_live
                          , ssr_data_schedule
                          // , ssr_data_hosts
                          , params
                          , navtype 
                          , navoption
                          , navtitle
                          , navurl
                          , navurl_relative
                          , helptype
                          , nodata
                        }) {
  

  //VARS 
  var user_name = ''

  const page = 0
  const [sort, set_sort] = useState("broadcast")
  const cdir = config.itemdir
  const [dir, set_dir] = useState(cdir)  

   //search
 let [isOpen, set_isOpen] = useState(false)
 const [results,set_results] = useState(null)
 const [search,set_search] = useState('')
 
 const [cache, set_cache] = useState(getCacheDate())

  //PRELOAD SSR DATA

  const router = useRouter()
  
  const {descriptionRef} = useTimestamp(ssr_data_posts)
  const { userdata, isUserDataLoading, isUserDataError } = useUserAuth()
  const [view_mode,set_view_mode] = useState(navtype)
  const [is_searching, set_is_searching] = useState(false)
  const [search_text,set_search_text] = useState('')
  const [filter_text,set_filter_text] = useState('')


  const [change_cache,set_change_cache] = useState(null)
  const [display_view,set_display_view] = useState(navtype)
  const [accessby_view,set_accessby_view] = useState(navoption)
  const [swr_url,set_swr_url] = useState(`/api/private/post/select?option=${navoption}`)

  const ably = useContext(PushContext);
  const {player_state,set_player_state} = useContext(PlayerContext)

  useEffect(()=>{
    if (accessby_view !== "tag") set_swr_url(`/api/private/post/select?option=${accessby_view}`)
  },[accessby_view])

  useEffect(()=>{set_display_view(navtype)},[navtype])



  //slow routing from next.js query parsing

  const submitSearch = (e) => {
    e.preventDefault()
    if (search_text?.length > 0) {
      set_accessby_view('tag')
      set_filter_text(search_text)
      set_swr_url(`/api/private/post/select?option=tag&q=tag:${search_text}`)
      
      router.push({
        pathname: display_view=="post" ? `/` : `/audio`,
        query: { search: search_text}
      }, 
      undefined, { shallow: true }
      )
    }
   
  }

  const clearSearch = (e) => {
    e.preventDefault()
    set_filter_text('')
    set_search_text('')
    set_is_searching(false);
    set_swr_url(`/api/private/post/select?option=${accessby_view !== "tag" ? accessby_view : "featured"}`)
    router.push({
      pathname: display_view=="post" ? `/` : `/audio`,
      query: {  }
    }, 
    undefined, { shallow: true }
    )
  }
  const [session, loading] = useSession()

  const [schedulearraydata_active, set_schedulearraydata_active] = useState((ssr_data_schedule))
  // const [hostsjsondata_active,set_hostsjsondata_active] = useState(ssr_data_hosts)
  const [livefilter, set_livefilter] = useState(1)
  const liveRef = useRef(null);
  const { onMouseDown } = useDraggableScroll(liveRef, { direction: 'horizontal' });

  //PRELOAD SSR DATA
  const [livedata_active, set_livedata_active] = useState((ssr_data_live))


  useEffect(()=>{
    if (livedata_active?.data?.filter(e=>(e?.bol_featured == 1 && e?.bol_show_chat == 1 && e?.chat_permissions !== 0))?.length == 0) {
      set_livefilter(-1)
    }
  },[
    livedata_active?.data,
  ])




  //SEO TRICK - RECLAIM PREVIOUS SITEMAP INDEX
  if (JSON.stringify(router.query) == '{"sitemap.xml":""}') {
    if (typeof window !== 'undefined') window.location.href = `sitemap.xml`;
  }

  //LIVE DATA
  const { 
    data: livearraydata
  , loading: livearraydataisLoading
  , error: livearraydataisError
  , boundMutate: itemsMutate
                  } = useSWRWrapper(
                    '0' //id
                    ,`/api/private/user/select-by-live?page=${page}&sort=${sort}&dir=${dir}&q=${''}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,config.profile.stream_interval_host //refreshinterval (0=none)
                    )

  useEffect(() => {
    
    if (!(livearraydataisLoading) && !(livearraydataisError) && livearraydata) {
      //console.log(livearraydata)

      // RANDOM VIEW ONLY:
      // map over sort the info by the original index so the page contents dont bounce around. 
      const livearraydata_sorted = sort !== "RAND" 
                                      ? livearraydata
                                      : { 
                                         meta: livearraydata.meta
                                        ,data: ssr_data_live?.data.map((i) => livearraydata.data.find((j) => j.id === i.id))
                                      };



      //ssr_data has the correct index

      //map through profiledata

      //


      set_livedata_active(livearraydata_sorted)
    }
  }, [
        livearraydata
      , livearraydataisLoading
      , livearraydataisError
      , sort
      , ssr_data_live?.data
    ])

  
  //SCHEDULE DATA
  const { 
    data: schedulearraydata
  , loading: schedulearraydataisLoading
  , error: schedulearraydataisError
  , boundMutate: scheduleitemsMutate
                  } = useSWRWrapper(
                    '0' //id
                    ,`/api/private/user-schedule/select-by?page=${0}&sort=${sort}&dir=${dir}&cache=${cache}&q=${''}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    )

  const mutateItemsDeleteOne = (id) => {
    const newData = omitArrayItemsWithMatchingKeyValuePair(
        'item_id'
        , id
        , schedulearraydata.data
      )
    itemsMutate({
      ...schedulearraydata
      , data: newData
    })
  }

  useEffect(()=> {
    if (!schedulearraydataisLoading && !schedulearraydataisError && schedulearraydata) {
      set_schedulearraydata_active(schedulearraydata)
    }

  },[
     schedulearraydata
    ,schedulearraydataisLoading
    ,schedulearraydataisError
  ])


  //DAY OF THE WEEK
  const d = new Date();
  let today = d.getDay()+1;
  let tomorrow = d.getDay()+2 > 7 ? 1 : d.getDay()+2;
  var time = d.toLocaleTimeString('en-US', { hour12: false }).toString()
  let diff = d.getTimezoneOffset();

  //override 24 hour string so it appears like 00:... instead of 24:... for mysql consistency
  const time_arr = time.split(":")
  if (time_arr[0] == "24") time = `00:${time_arr[1]}:${time_arr[2]}`



  const todays_shows = schedulearraydata_active?.data?.filter((f) => f?.bol_active?.toString() == "1")?.filter((e) => e?.weekday?.toString() == today && e?.start_time?.toString() > time && e?.frequency!=='adhoc')
  const tomorrows_shows = schedulearraydata_active?.data?.filter((f) => f?.bol_active?.toString() == "1")?.filter((e) => e?.weekday?.toString() == tomorrow && e?.frequency!=='adhoc')
  const upcoming_shows = todays_shows?.length > 0
                         ? todays_shows
                         : tomorrows_shows

  const upcoming_shows_length = todays_shows?.length > 0
                         ? todays_shows?.length
                         : tomorrows_shows?.length    

  const schedule_tabs = [
    {
        text:'Hot',
        value:1,
        hint:livefilter == 1
              ? 0
              : livedata_active?.data?.filter(e=>(e?.bol_featured == 1 && e?.bol_show_chat == 1 && e?.chat_permissions !== 0))?.length
      },
      {
        text:'All',
        value:0,
        hint:livefilter == 0
            ? 0
            : livedata_active?.data?.filter(e=>(e?.bol_featured == 0 || e?.bol_show_chat == 0 || e?.chat_permissions == 0))?.length
      },
      {
        text:'Upcoming',
        value:-1,
        hint:livefilter == -1
            ? 0
            : upcoming_shows_length
      }
  ]


  //slow routing from next.js query parsing

  useEffect(()=>{
    if (router?.query?.option == "featured") set_accessby_view("featured")
    if (router?.query?.option == "followed") set_accessby_view("followed")
    if (router?.query?.option == "all") set_accessby_view("all")
    if (router?.query?.search?.length > 0) {
      set_accessby_view("tag")
      set_filter_text(search_text)
      set_swr_url(`/api/private/post/select?option=tag&q=tag:${router?.query?.search}`)
    }
    set_search_text(router?.query?.search?.toString())
  },[router])




      //Admin Lookup
      const [isAdmin,set_isAdmin] = useState(userdata?.bol_admin?.toString()==1)
      

      const set_tab_header = (n,url) => {
        set_display_view(n);
        router.push(url, undefined, { shallow: true });
      }

  const get_channel_listen = (id) => {
    
    return ably?.channels?.get(`${ssr_data_tribe?.brand_id}-${config.websockets.listen}${id}`)
  } 


  return <>
      {/* MAIN CONTAINER */}
      <LayoutPageBlank
       title={navtitle}
       url={!hasData(search_text) ? navurl : `${navurl}?search=${search_text}`}
       image={meta(ssr_data_tribe).image}
       ssr_data_tribe={ssr_data_tribe}
      >

      <NavPrimary
            user_name={userdata?.user_name}
            userdata={userdata}
            isUserDataLoading={isUserDataLoading}
            isUserDataError={isUserDataError}
            navtype={navtype}
            ssr_data_tribe={ssr_data_tribe}
            />

          <LayoutPageBlankContentWrapper>

            <HeroCTA
              ssr_data_tribe={ssr_data_tribe}
            />

            <div className="md:flex items-top content-start">
              <div className="flex-0 md:w-64 md:min-w-64 shrink-0">
                              {/* LIVE SHOWS */}
                              <div className="md:sticky top-[54px]">  
                              <div  className="bg-gray-900 z-100 shadow-md  border border-gray-700 m-2 rounded-md">


                                  <div className={`border-b border-gray-700 `}>


                                    
                                      <div className="md:hidden block ">   
                                        <FilterHeaderHome
                                          headertitle={<>Live Shows</>}
                                            filter={livefilter}
                                            set_filter={set_livefilter}
                                            filterarray={schedule_tabs}
                                          />
                                          </div> 
                                          <div className="hidden md:block">   
                                            <FilterHeaderHome
                                              headertitle={<>{textProcess(`Live Shows`)}</>}
                                                filter={livefilter}
                                                set_filter={set_livefilter}
                                                filterarray={[]}
                                              />
                                          </div> 

                                    </div>
                                    <div className="hidden md:block">   
                                    <div className="flex items-center content-center mx-auto  justify-center
                                    border-b border-gray-700 
                                    divide-x divide-gray-700 
                                    ">


                                        {schedule_tabs.map((e,index)=>
                                            
                                            
                                            <FilterTabHomeMini
                                                key={index}
                                                filter={livefilter}
                                                set_filter={set_livefilter}
                                                tab_value={e.value}
                                                tab_text={textProcess(e.text)}
                                                hint={e.hint}
                                            />
                                            

                                        )}
                                    </div>

                                        
                                  
                                      </div> 

                                    <div className=" w-full mt-2 -mb-5 ">

                                      <div className="px-2 pb-6 w-full flex md:inline-block flex-row items-center content-center overflow-x-auto md:overflow-x-visible"
                                      
                                        ref={liveRef} 
                                        onMouseDown={onMouseDown}
                                        onDragStart={onMouseDown}
                                        onDrag={onMouseDown}
                                      >

                                        {livefilter >= 0 && //featured and all
                                        <>
                                        {/* FEATURED */}
                                        {livedata_active?.data?.filter(e=>(
                                                                            (livefilter == 1 ? e?.bol_featured == 1 : 1==1) && //only allow featured broadcasters
                                                                            e?.bol_show_chat == 1 &&
                                                                            e?.chat_permissions !== 0))?.map((e,index)=>{
                                          return (
                                            <div key={e?.id} className="mb-2 inline-block align-top"
                                            tabIndex={0}
                                            onClick={()=> {
                                              if (userdata?.id?.toString() !== e?.id) startPlayer(true,e,player_state,set_player_state,userdata,get_channel_listen(e?.id))}
                                            }
                                            onKeyDown={(x)=> {
                                                if ([13,32].indexOf(x.keyCode) > -1) {
                                                  if (userdata?.id?.toString() !== e?.id) startPlayer(true,e,player_state,set_player_state,userdata,get_channel_listen(e?.id))
                                                }
                                              }
                                            }
                                            >
                                              <div className="hidden xs:block align-top">
                                                      <Link href={`/${e.user_name}/chat`}>

                                                        <ProfileAvatarLive
                                                          profiledata={e}
                                                        
                                                        />

                                                      </Link>
                                              </div>
                                              <div className="block xs:hidden align-top">
                                                      <Link href={`/${e.user_name}/chat?mobile=1`}>

                                                        <ProfileAvatarLive
                                                          profiledata={e}
                                                        
                                                        />

                                                      </Link>
                                              </div>
                                            </div>
                                          );
                                        })}
                                        

                                        {/* ALL BROADCASTS */}
                                        {livedata_active?.data?.filter(e=>((livefilter == 1 ? e?.bol_featured == 1 : 1==1) //allow everyone
                                                                           && e?.bol_show_chat == 1 
                                                                           && e?.chat_permissions !== 0))?.length == 0 &&
                                        <div className="pb-4 text-center text-sm mx-auto h-[120px] flex items-center content-center ">
                                          <div className="flex-1 ">
                                          <div className="font-bold text-lg ">{textProcess(`All quiet!`)}</div>
                                          <div className="italic">
                                            <div>Check for <span className="cursor-pointer underline hover:no-underline" onClick={()=>set_livefilter(-1)}>upcoming shows</span></div>
                                            <div>or <span className="cursor-pointer underline hover:no-underline" 
                                                    onClick={()=>{
                                                      if (session && typeof window !== undefined) {
                                                        openWindow(config.broadcast.app_base_url, config.broadcast.title, window, config.broadcast.width, config.broadcast.height);
                                                      } else {
                                                        signIn()
                                                      }}}>start streaming</span>.</div>
                                          </div>
                                          </div>
                                        </div>
                                        }
                                        </>
                                        }

                                        {livefilter == -1 && //upcoming
                                        <div className="pb-4 w-full min-h-[120px]  overflow-y-auto flex items-center content-center ">
                                        <div className="flex-1 max-h-[250px]">
                                      
                                        <ScheduleArrayHome
                                          itemdata={upcoming_shows}
                                          itemmeta={schedulearraydata_active?.meta}
                                          isLoading={false} //{schedulearraydataisLoading}
                                          isError={false} //{schedulearraydataisError}
                                          sum_items={upcoming_shows_length}
                                          mutateItemsDeleteOne={mutateItemsDeleteOne}
                                          userdata={userdata}
                                          ownerdata={{
                                            id:0
                                            ,user_name:user_name
                                            ,bol_admin_help:0
                                            ,bol_staff_help:0
                                            ,staff_json:'[]'
                                            ,block_json: '[]'
                                          }}
                                          details_collapsed={true}
                                          display_view={"minihome"}
                                          cache={cache}
                                          set_cache={set_cache} 
                                          slugfilter={null}
                                          show_adminbar={false}
                                          nodata={nodata}
                                          navtype={'schedule'}
                                          helptype={'content'}
                                          isAdmin={false}
                                        />
                                        </div>
                                        </div>
                                        }
                                      </div>

                                    
                                    

                                
                                      
                                    </div>

                                
                              </div>

                                          
                              <div className="hidden mt-4 flex-1 md:block divide-gray-700 gap-1 justify-center px-2 bg-gray-900">

                                            {homebarlist(ssr_data_tribe)?.map((e,index) => 
                                              
                                              <Fragment key={index}>
                                                {(
                                                  ["contribute","discord"].indexOf(e?.type) == -1
                                                  || (["contribute"].indexOf(e?.type) > -1 && ssr_data_tribe?.bol_contribute == "1")
                                                  || (["discord"].indexOf(e?.type) > -1 && ssr_data_tribe?.bol_discord == "1")
                                                ) && 

                                              
                                                <PopupMenuOptionLink
                                                  key={index}
                                                  icon={e.icon}
                                                  iconclass={e.iconclass}
                                                  name={textProcess(e.name)}
                                                  type={e.type}
                                                  url={e.url}
                                                  navtype={navtype}
                                                  behavior={e.behavior}
                                                />

                                                }
                                              </Fragment>
                                              )}




                                              
                                     </div>
                              </div>



              </div>
              <div className="flex-1">
                              <div className="z-50 md:mt-2 mt-3 border-x rounded-t-md border-gray-700 shadow-md sticky top-[47px]">
   

                              <HeaderBarHome
                                  headertitle={<div className="flex items-center content-center">
                                                  <div>
                                                    <Tabs
                                                      tabarray={tabarray_posts_filter('',{},false)}
                                                      clickval={display_view}
                                                      set_clickval={set_tab_header}
                                                      tab_style="selectboxhome"
                                                    />
                                                  </div>
                                                </div>}
                                  show_rss={true}
                                  rss_link={`/audio/podcast.rss`}
                                  rss_text={'RSS'}
                                  action_button={<>
                                  </>}
                                  show_action_button={false}
                                /> 


                              <HeaderBarHome
                                        headertitle={<form
                                          onSubmit={(e)=> submitSearch(e)}
                                        >
                                        <div className="flex text-sm font-base w-full group cursor-pointer ">
                                          <input 
                                            className={`transition-all duration-500 border rounded-l-md  border-gray-700 group-hover:border-white  bg-gray-700 placeholder-gray-500 text-white px-2
                                              ${is_searching ? "flex-1 w-full " : "flex-0 w-20 xs:w-28 md:w-48 lg:w-full"}
                                            `}
                                            onChange={(e)=>
                                                //set_is_searching(e.target.value.length > 0)
                                                set_search_text(e.target.value)
                                              }
                                            onFocus={(e)=>{set_is_searching(true)}}
                                            onBlur={(e)=>{set_is_searching(e.target.value.length > 0)}}
                                            defaultValue={search_text}
                                            placeholder={textProcess(`Search`)}
                                           
                                          />
                                          
                                          <div 
                                            className="border border-gray-700 group-hover:border-white p-2 rounded-r-md"
                                            onClick={(e)=> submitSearch(e)}
                                            role="button"
                                            tabIndex={0}
                                            onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                                              submitSearch(e)
                                            }}}
                                          >
                                            <SvgJsx 
                                              type={'fill'}
                                              icon={'search-sm'}
                                              className={`h-5 w-5`}
                                              title={textProcess(`Search`)}
                                            />
                                          </div>
      
                                            {is_searching &&
                                            <div 
                                              className=" hover:text-white  p-2 "
                                              onClick={(e)=> clearSearch(e)}
                                              onKeyDown={async (e)=> {if ([13,32].indexOf(e.keyCode) > -1) {
                                                
                                                clearSearch(e);
                                              }}}
                                              role="button"
                                              
                                              tabIndex={0}
                                            >
                                              <SvgJsx 
                                                type={'fill'}
                                                icon={'x-sm'}
                                                className={`h-5 w-5`}
                                                title={'Clear'}
                                              />
                                            </div>
                                            }
                                          </div>
                                        </form>
                                        
                                      }
                                        show_rss={false}
                                        rss_link={`/audio/podcast.rss`}
                                        rss_text={'rss'}
                                        action_button={<>
                                        {!is_searching &&
                                        <Tabs
                                          tabarray={tabarray_posts_global(navtype)}
                                          clickval={accessby_view}
                                          set_clickval={set_accessby_view}
                                          tab_style="selectboxhome"
                                          userdata={userdata}
                                        />
                                        }
                                        </>}
                                      
                                        show_action_button={!is_searching}
                                        top={false}
                                      />

                                                                   

                               
                              </div>
                        
                              <div className="max-w-7xl mx-auto border-x border-y border-gray-700">


                                
                                <PostArray 
                                      itemdata={ssr_data_posts}
                                      isLoading={ false}
                                      isError={ false }
                                      userdata={userdata}
                                      ownerdata={null}
                                      display_view={display_view}
                                      set_display_view={set_display_view}
                                      cache={cache}
                                      set_cache={set_cache}
                                      slugfilter={''}
                                      placeholderdata={[
                                      //   {
                                      //    post_title: 'Loading'
                                      //   ,crdate: +new Date().toISOString()
                                      //   ,start_date: +new Date().toISOString()
                                      //   ,end_date: +new Date().toISOString()
                                      //   ,post_contents_json: []
                                      //   ,bol_status: -1
                                      //   ,bol_public: 0
                                      //   ,bol_recording: 0
                                      //   ,user_id: profiledata?.id
                                      //   ,user_name: 'Lurker'
                                      //   ,avatar_url: ''
                                      //   ,post_id: -1
                                      // }
                                      ]}
                                      nodata={nodata}
                                      navtype={navtype}
                                      helptype={helptype}
                                      isAdmin={isAdmin}
                                      slug={''}
                                      title={'All posts'}
                                      //swr_url={`/api/private/post/select?option=${accessby_view}${(accessby_view == "tag" && search_text?.length > 0) ? `&q=tag:${params?.tag}` : ""}`}
                                      swr_url={swr_url}
                                      descriptionRef={descriptionRef}
                                      ssr_data_tribe={ssr_data_tribe}    
                                      origin_url={navurl_relative}
                                      origin_title={navtitle}
                                      />
                              </div>
              </div>
            </div>





        </LayoutPageBlankContentWrapper>
        <Footer />
      </LayoutPageBlank>
    </>;


  }
  

  export default IndexPosts