
export default function HeaderRowHome({
    headertitle
    ,action
}) {
    return (<>
        <div className="flex items-center content-center bg-gray-800 rounded-t-md">
            <div className={'flex-1 ml-2'}>
                <h2 
                    className={`font-extrabold text-md md:text-lg
                               text-white
                                `}
                >
                    {headertitle}
                </h2>
            </div>
           
            <div
                className="flex-0 flex items-center content-center   mr-2 "
            >
                {action}
            </div>
          
        </div>
    </>)
}