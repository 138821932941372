import isAdminUser from '@/lib/utils/isAdminUser'
import React, { useEffect, useState } from 'react'
import ScheduleSingle from '../schedule/schedule-single'
import ScheduleSingleHome from './schedule-single-home'

export default function ScheduleStateContainerHome({
    
     itemdata
    ,placeholderdata
    ,userdata
    ,ownerdata
    ,placeholder=false
    ,details_collapsed
    ,display_view
    ,cache
    ,set_cache
    ,nodata
    ,navtype
    ,helptype
    ,isAdmin
}

) {

    const [isAdmin_schedule,set_isAdmin_schedule] = useState(isAdmin)
    useEffect(()=>{

      set_isAdmin_schedule(isAdminUser( 
                               itemdata?.user_id            //user_id
                              ,itemdata?.bol_staff_help    //bol_staff_help
                              ,itemdata?.staff_json        //staff_json
                              ,itemdata?.bol_admin_help    //bol_admin_help
                              ,userdata?.id?.toString()            //my_id
                              ,userdata?.bol_admin?.toString()    //global_admin
                              ,helptype
                              ))

    },[
         itemdata
        ,userdata
        ,helptype
    ])


        return (<div className="">
            <ScheduleSingleHome
                itemdata={itemdata}
                placeholderdata={placeholderdata}
                userdata={userdata}
                ownerdata={ownerdata}
                placeholder={placeholder}
                details_collapsed={details_collapsed}
                display_view={display_view}
                cache={cache}
                set_cache={set_cache}
                nodata={nodata}
                navtype={navtype}
                helptype={helptype}
                isAdmin={isAdmin_schedule}
            />
        </div>)
    

}