
import SvgJsx from "@/components/templateux/svg/svg-jsx"
import { hasData } from "@/lib/utils/hasData"
import { IsJsonValid } from "@/lib/utils/isJsonValid"
import { textProcess } from "@/lib/utils/textprocess"
import Link from "next/link"
import { Fragment, useEffect, useState } from "react"

function ScheduleSingleMiniHome({
    display_data
    ,getWeekday
    ,getNormalTime
    ,userdata
}) {


    const [all_folks, set_all_folks] = useState((display_data?.bol_hosts_json?.toString()=="1" && IsJsonValid(display_data?.hosts_json)) ? JSON.parse(display_data?.hosts_json)?.filter((e)=>e?.type !== "crew") : [])
    useEffect(()=>{
      if (display_data?.bol_hosts_json?.toString()=="1" 
        && IsJsonValid(display_data?.hosts_json)) {
          set_all_folks(JSON.parse(display_data?.hosts_json)?.filter((e)=>e?.type !== "crew"))
      }
    },[display_data?.bol_hosts_json])

    const Linkify = (name,id) => {
      if (id == 0) {
        return (<>{name}</>)
      }
      return <><Link href={`/${name}`} className="underline hover:no-underline">

        {name}
      </Link></>;
    }

    return (

       
        <div className='flex-1 w-full flex items-center content-center group
        bg-gray-900  '>

          <div className='flex-1 
          '>
            
                <div className=' sm:block flex items-center content-center my-2 '>
                  <div className='flex-0 mr-2 text-light w-24 sm:w-full'>
                    <div className="font-thin">
                      {textProcess(getWeekday(display_data?.weekday,"full"))} @ {getNormalTime(display_data?.start_time,true)} {hasData(display_data?.timezone_display) ? display_data?.timezone_display : ''}
                    </div>
                  </div>
                  <div className='flex-1 ml-2 sm:ml-0'>
                    

                          <div className='flex items-center content-center  '>
                            <div className='flex-1'>
                              <div className=' text-white '>
                                <div className=''>
                                  <span className="font-bold">
                                    {textProcess(display_data?.show_name)} 
                                  </span>
                                </div>
                                <div className='italic text-xs sm:text-sm'>
                                  <span >
                                    {textProcess(`featuring`)} {Linkify(display_data?.user_name,display_data?.id)} 
                                  </span>
                                  {all_folks.map((e,index)=> 
                                  <Fragment key={index}>
                                    {index < all_folks.length-1 &&
                                    <span className=''>
                                    ,  {Linkify(e?.name,e?.id)}  
                                    </span>
                                    }

                                    {index == all_folks.length-1 &&
                                    <span className='ml-1'>
                                    &amp;  {Linkify(e?.name,e?.id)} 
                                    </span>
                                    }
                                  </Fragment>
                                  )}
                                </div>
                              </div>

                            </div>
                            
                            
                            
                          
                          </div>

                  </div>
                  
                </div>
          </div>
          
       
        </div>

      )
}

export default ScheduleSingleMiniHome