
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import AuthorCredit from "@/components/templateux/user/author-credit";
import AvatarFlairLive from "@/components/templateux/user/avatar-flair-live";
import { hasData } from "@/lib/utils/hasData";
import ProfileContributorTag from "../profile-contributor-tag";


export default function ProfileAvatarLive({
  profiledata
}) {



  return (<>
    <div className={`flex flex-col items-center content-center justify-center text-center relative group cursor-pointer 
    hover:bg-gray-700 p-2 rounded-md w-[110px] overflow-hidden`}>
      <div className=" flex-0 px-3"
        title={profiledata?.user_name}>
        <AvatarFlairLive 
            
            isPlaying={(profiledata?.stream_status?.toString()=="1" 
                        && (!hasData(profiledata?.bol_send_notifications) 
                        || profiledata?.bol_send_notifications?.toString() == "1")
                        )}
            isContributor={profiledata?.contributor_level > 0}
            avatar_url={profiledata?.avatar_url}
            avatar_url_sfw={profiledata?.avatar_url_sfw}
            bol_nsfw={profiledata?.bol_nsfw}
            avatar_crdate={profiledata?.avatar_crdate}
            id={profiledata?.id}
            user_name={profiledata?.user_name}
            
            containerClass={`w-[80px] h-[80px]`}
            sizes={{
                          width: 80
                          ,height: 80
                          ,quality: 100
                          }}
            bgClass={`w-[80px] h-[80px]`}
            avatarClass={`border-4 border-transparent w-[80px] h-[80px]`}
            rotaryContainerClass={`w-[80px] h-[80px] `}
            rotaryInternalClass={`w-[88px] h-[88px] -mr-[6px]`}
          />
      </div>
      <div className={`font-bold  mt-[40px] flex items-center content-center
        ${profiledata?.user_name?.length >= 15 
          ? 'text-3xs' 
          : profiledata?.user_name?.length >= 10 
            ? 'text-2xs' 
            : profiledata?.user_name?.length >= 5 
              ? 'text-xs'
              : 'text-sm'}
      `}>
        <div>
        {profiledata?.user_name}
        </div>
        {profiledata?.contributor_level > 0 &&
        <div className="ml-1">
          <ProfileContributorTag  
            showReminder={false}
            size={12}
          />
        </div>
        }
      
      
      </div>
      {(profiledata?.connections_listen > 5 || profiledata?.connections_listen > 5)  &&
      <div className="text-2xs flex items-center content-center bg-gray-600 px-1.5 py-0.5 rounded-md mt-1">
        
         <SvgJsx 
                  type={'fill'}
                  icon={"user-group-sm"}
                  className={`h-3 w-3  `}
                  title={"Users"}
              /> 
              <div className="ml-1">{profiledata?.connections_listen < profiledata?.connections_chat
                ? profiledata?.connections_chat
                : profiledata?.connections_listen}</div>
      </div>
      }

    </div>
</>)


}