
import FilterTabHome from "./filter-tab-home";
import HeaderRowHome from "./headerrow-home";

export default function FilterHeaderHome({
    headertitle
    ,filter
    ,set_filter
    ,filterarray
}) {

  

    return (<>
                <HeaderRowHome
                    headertitle={headertitle}
                    action={(<>
                        
                        <div className="flex h-12 items-center content-center mx-auto  justify-center ">


                                    {filterarray.map((e,index)=>
                                        
                                        
                                        <FilterTabHome
                                            key={index}
                                            filter={filter}
                                            set_filter={set_filter}
                                            tab_value={e.value}
                                            tab_text={e.text}
                                            round_left={index==0}
                                            round_right={ index == filterarray.length-1}
                                            hint={e.hint}
                                        />
                                        

                                    )}

                                    </div>
                            
                        </>)}
                    
                />
                
                         
                
    </>)

}



