
import { RssLink } from "../rss-link";

export default function HeaderBarHome({
    headertitle
    ,show_rss
    ,rss_link
    ,rss_text
    ,show_action_button=true
    ,action_button= <></>
    ,top=true
}) {


 

        return (<>
            
             <div className={`bg-gray-800 ${top ? "border-t rounded-t-md" : ""}  border-gray-700`}>
                <div className=" flex items-center justify-between border-b h-16
                             border-gray-700
                ">
    
                    <div className="flex-1 flex items-center justify-between">
    
                        <div className=" sm:flex flex-1 sm:items-center sm:justify-start sm:inset-y-0 sm:left-0 mx-4" >
                            <h2 className="flex-1 w-full  font-extrabold inline-block text-xl lg:text-3xl">
                                {headertitle}
                            </h2>
                        </div>
        
                        
                            {(show_action_button) &&
                            <div className="mr-4 flex-0 flex  justify-between">
                                <div className="flex-0 flex ml-1">
                                
                                       {action_button}
                                    
                                </div> 
                            </div>
                            }

                            {show_rss &&
                            <div className="flex-0 flex ml-1 mr-4">
                                <RssLink
                                    show_rss={show_rss}
                                    rss_link={rss_link}
                                    rss_text={rss_text}
                                />
                            </div>
                            }      
                       
                      
    
                    </div>
                </div>
            </div> 


        </>)

}
