
import { getStreamUrl, getUserListenExperience } from "./getStreamUrl";
import { hasData } from "./hasData"
import NoSleep from 'nosleep.js';

export const startPlayer = async (isPlaying,itemdata,player_state,set_player_state,userdata,channel,seek=0) => {

    //reset player completely
    //console.log("player_state",player_state,channel)
    let volume = player_state?.volume;
    let volume_slider = player_state?.volume_slider;
    //await set_player_state({})


  let stream_url_active = getStreamUrl(
      itemdata?.bol_stream_local,
      itemdata?.stream_url_active,
      itemdata?.stream_url,
      userdata?.listen_experience,
      player_state)

   await set_player_state(prev=>{
    return {...prev
      ,file:`${stream_url_active}`
      ,url:`${stream_url_active}`
      ,seek:seek
      ,post_id: itemdata?.post_id
      ,content_id:itemdata?.broadcast_id //this pulls from the user table so do not use content_id
      ,content_table:hasData(itemdata?.content_table) 
                    ? itemdata?.content_table 
                    : 'post'
      ,user_id: itemdata?.id
      ,user_name: itemdata?.user_name
      ,player_type: 'live'
      ,title: itemdata?.broadcast_title
      ,avatar_url: itemdata?.avatar_url
      ,stream_start_date:  itemdata?.stream_start_date
      ,server_timestamp: itemdata?.timestamp
      ,isMuted: false
      ,isPlaying: isPlaying
      ,isEnded: false
      ,stream_status: itemdata?.stream_status //is this needed?
      ,broadcast_status: itemdata?.stream_status
      ,listener: null
      ,volume: volume
      ,volume_slider: volume_slider
      ,isHide: "0"
      ,listen_experience: getUserListenExperience(userdata) 
      }
    })

    // channel.presence.get(function(err, data) {

    //   console.log("start player",data);

    // });

    if (channel !== undefined) {

      channel?.presence?.update({
        id: userdata?.id
       ,user_name: userdata?.user_name
       ,avatar_url: userdata?.avatar_url
       ,avatar_url_sfw: userdata?.avatar_url_sfw
       ,avatar_crdate: userdata?.avatar_crdate
       ,bol_nsfw: userdata?.bol_nsfw
       ,contributor_level: userdata?.contributor_level
       ,custom_flair: ''
       ,chat: false
       ,listen: isPlaying
      });

    }

    // Enable wake lock.
    var noSleep = new NoSleep();
// (must be wrapped in a user input event handler e.g. a mouse or touch handler)
  document.addEventListener('click', function enableNoSleep() {
    document.removeEventListener('click', enableNoSleep, false);
    noSleep.enable();
  }, false);

}